import _ from 'lodash';

/**
 * Extract the first instance of component type
 * from google maps complex components.
 *
 * E.g. with country:
 * [{...}, {...}] => ["FR", "FR", "FR"] => "FR"
 */
function filterComponents(results, type) {
  return _(results)
    .map((result) => result.address_components
      .filter((component) => _.includes(component.types, type))
      .map((component) => component.short_name))
    .flatten()
    .first();
}

function geocode(location) {
  const geocoder = new window.google.maps.Geocoder();

  const promise = new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        location,
      },
      (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else {
          console.log(`Geocoder failed due to: ${status}`);
          reject();
        }
      },
    );
  });

  return promise;
}

export { geocode, filterComponents };
